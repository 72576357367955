var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.static_page
        ? _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { width: "80%" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "justify-center mt-3",
                  attrs: { "primary-title": "" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "headline font-weight-bold center-text" },
                    [_vm._v(" " + _vm._s(_vm.$t("statics.title.form")) + " ")]
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                {
                                  model: {
                                    value: _vm.staticForm,
                                    callback: function ($$v) {
                                      _vm.staticForm = $$v
                                    },
                                    expression: "staticForm",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "String",
                                      label: _vm.$t("statics.pageId"),
                                      rules: [
                                        () =>
                                          !!_vm.static_page.id ||
                                          _vm.$t("statics.validation.pageId"),
                                      ],
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.static_page.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.static_page, "id", $$v)
                                      },
                                      expression: "static_page.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("ckeditor", {
                                attrs: {
                                  editor: _vm.editor,
                                  config: _vm.editorConfig,
                                },
                                model: {
                                  value: _vm.static_page.html,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.static_page, "html", $$v)
                                  },
                                  expression: "static_page.html",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  this.$route.params.id
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "error mr-4",
                          on: {
                            click: function ($event) {
                              _vm.deleteDialog = true
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("delete")) + " "),
                          _c("v-icon", [_vm._v("delete")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.staticForm },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteDialog,
          title: _vm.$t("statics.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("statics.delete_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteDialog = false
          },
          submit: _vm.deleteStaticPage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }