<template>
  <div>
    <v-card v-if="static_page" class="mx-auto" width="80%">
      <v-card-title primary-title class="justify-center mt-3">
        <span class="headline font-weight-bold center-text">
          {{ $t("statics.title.form") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-form v-model="staticForm">
                <v-text-field
                  v-model="static_page.id"
                  type="String"
                  :label="$t('statics.pageId')"
                  :rules="[
                    () => !!static_page.id || $t('statics.validation.pageId'),
                  ]"
                  required
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ckeditor
                :editor="editor"
                v-model="static_page.html"
                :config="editorConfig"
              >
              </ckeditor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="this.$route.params.id"
          class="error mr-4"
          @click="deleteDialog = true"
        >
          {{ $t("delete") }}
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn @click="back()">{{ $t("cancel") }}</v-btn>
        <v-btn @click="save()" :disabled="!staticForm">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialogo para la eliminacion de paginas estaticas -->
    <modal-dialog
      @cancel="deleteDialog = false"
      @submit="deleteStaticPage"
      :dialog="deleteDialog"
      :title="$t('statics.delete_title')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :content="$t('statics.delete_dialog')"
    ></modal-dialog>
  </div>
</template>

<script>
import engineStore from "@/services/engineStore";
import ModalDialog from "@/components/common/ModalDialog";
import ClassicEditor from "@/plugins/ckeditor.js";
import i18n from "@/plugins/i18n";

export default {
  name: "Static_PageForm",
  components: {
    ModalDialog,
  },
  data() {
    return {
      staticForm: false,
      deleteDialog: false,
      static_page: {},
      editor: ClassicEditor,
      editorConfig: {
        language: i18n.locale,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "blockquote",
            "code",
            "subscript",
            "superscript",
            "|",
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
            "|",
            "link",
            "imageUpload",
          ],
        },
        image: {
          toolbar: [
            "imageTextAlternative",
            "|",
            "imageStyle:alignCenter",
            "imageStyle:alignLeft",
            "imageStyle:alignRight",
          ],

          styles: ["alignCenter", "alignLeft", "alignRight"],
        },
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.load();
    }
  },
  methods: {
    load() {
      Object.assign(
        this.static_page,
        engineStore.statics.find((s) => s.id == this.$route.params.id)
      );
    },
    back() {
      this.$router.go(-1);
    },
    _validate(static_page) {
      if (!this.static_page.id) {
        // Check if Page ID is provided
        this.$notify({
          type: "error",
          text: this.$t("statics.notification.missing_id"),
        });
        return false;
      } else if (
        !this.$route.params.id ||
        this.$route.params.id != static_page.id
      ) {
        // Check if Page ID already exists if we're not updating
        if (engineStore.statics.find((s) => s.id == static_page.id)) {
          this.$notify({
            type: "error",
            text: this.$t("statics.notification.duplicated"),
          });
          return false;
        }
      }
      return true;
    },
    save() {
      if (!this._validate(this.static_page)) return;

      if (this.$route.params.id) {
        const page = engineStore.statics.find(
          (s) => s.id == this.$route.params.id
        );
        Object.assign(page, this.static_page);
        this.$router.push({
          name: "Static_PageList",
        });
      } else {
        engineStore.statics.push(this.static_page);
        this.$router.push({
          name: "Static_PageList",
        });
      }
    },
    deleteStaticPage() {
      const idxToDelete = engineStore.statics.indexOf(
        engineStore.statics.find((s) => s.id == this.static_page.id)
      );
      engineStore.statics.splice(idxToDelete, 1);
      this.back();
    },
  },
};
</script>
